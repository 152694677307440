
const prefix = '/qw_school'
const auth_prefix = "/qw_school_auth"
const newPath = "/qw_new_api";
export default {

  // 获取订单的优惠活动列表
  getDiscountList: {
    url: `${prefix}/schoolcj/order/api/payModule/webApi/web/getDiscountList`,
    method: 'get'
  },

/***
 * 获取优惠券
 * goodsId: 商品id    int
 * schoolId: 校区id   string
 * specificationNameId1: 一级规格id   int
 * specificationNameId2: 二级班型ids  array
 * http://10.0.98.146:81/schoolcj/marketing/swagger-ui.html#!/market-activity-controller/findActivityDetailUsingPOST
 */
 findActivityDetail:{
  url: `${prefix}/schoolcj/marketing/marketing/findActivityDetail`,
  method: 'post'
 },
//  获取不可用优惠
findActivityDetail_notInUserGroup:{
  url: `${prefix}/schoolcj/marketing/marketing/findActivityDetail_notInUserGroup`,
  method: 'post'
 },

/***
 * 验证支付流水号
 */
checkSerialNumber: {
  url: `${prefix}/schoolcj/order/api/payModule/webApi/web/checkSerialNumber`,
  method: 'get'
},

/***
 * oss签名
 */
ossSigniture: {
  url:`${auth_prefix}/ext/api/fileServerConfigApi/signiture`,
  method: 'get'
},

/***
 * 获取收支项
 */
queryOrderInOutProjectDto:{
  url:`${prefix}/schoolcj/finance/apiController/queryOrderInOutProjectDto`,
  method: 'get'
},


/***
*  提交支付凭证接口 
*/
payVoucher:{
  url: `${prefix}/schoolcj/order/api/payModule/webApi/web/payVoucher`,
  method: 'POST'
},

/***
 * 获取贷款方式
 */
 findLoanPlatform: {
   url: `${prefix}/hqcenter/goods/api/findLoanPlatform`,
   method: 'get'
 },

/**
 * 获取收款方式
*/
findSettlementMethod: {
  url: `${prefix}/hqcenter/goods/api/findSettlementMethod`,
  method: 'get'
},


/***
 * 获取资金方式
 */
 findFundMethod: {
  url: `${prefix}/hqcenter/goods/api/findFundMethod`,
  method:'get'
 },


 /***
  * 判断根据售后类型，检查订单那是否能发起售后
  */
  checkOrderByAfterSaleType: {
    url: `${prefix}/schoolcj/order/api/aftersale/checkOrderByAfterSaleType`,
    method: 'post'
  },
   
  docontractAudit: {
    url: `${prefix}/schoolcj/order/api/apiController/contract/audit`,
    method: 'post'
  },
  
  /***
   * 根据天翼的用户id，获取天翼的用户信息
   */
  getTyUserInfoById: {
    url: `${prefix}/api/user/getUserInfoById`,
    method: 'get'
  },

  findOrderRegistrationInformationVoByOrderId:{
    url: `${prefix}/schoolcj/order/wxTradeOrder/findOrderRegistrationInformationVoByOrderId`,
    method: 'get'
  },

  findCheckList: {
    url: `${auth_prefix}/hqcenter/order/contractRecord/findCheckList`,
    method: 'get'
  },

  getPreviewUrl:{
    url: `${prefix}/schoolcj/order/contractRecord/getPreviewUrl`,
    method: 'get'
  },

  getPreviewNewUrl:{
    url: `${prefix}/hqcenter/order/contractRecord/getPreviewUrl`,
    method: 'get'
  },

  getYunToken: {
    url: `${prefix}/schoolcj/order/contractRecord/getYunToken`,
    method:'get'
  },

  findByOrderId: {
    url: `${prefix}/schoolcj/order/contractRecord/findByOrderId`,
    method: 'get'
  },

  /***
   * 变更成交信息
   */
   saveOrderExtra: {
    url: `${prefix}/schoolcj/wxTradeOrder/saveOrderExtra`,
    method: 'post'
   },

  /**
   * 编辑收支项
   */
   addInoutByOrderId: {
    url: `${prefix}/schoolcj/order/api/apiController/addInoutByOrderId`,
    method: 'post'
   },

  //order/api/apiController/addInoutByOrderId

  /***
   * 收款明细
   */
   queryCollectDetailPageByOrderId: {
      url: `${prefix}/schoolcj/finance/apiController/queryCollectDetailPageByOrderId`,
      method: 'get'
   },


  /***
   * 组合套餐 收款明细
   */
   queryCollectDetailPageByOrderNew: {
    url: `${prefix}/schoolcj/finance/apiController/queryCollectDetailPageByOrderNew`,
    method: 'get'
   },


  /***
   * 获取商品详情
   */
   findCourseAndContentByGoodsIdNew: {
     url: `${prefix}/schoolcj/order/wxTradeOrder/findCourseAndContentByGoodsIdNew`,
     method: 'get'
   },


  /**
   * 订单nc推送
   */
   sendMsgToNcByOrderId: {
    url: `${prefix}/schoolcj/order/api/apiController/sendMsgToNcByOrderId`,
    method: 'get' 
   },


  /**
   * 获取订单状态信息
   */
   findOrderStatusByOrderId: {
      url: `${prefix}/schoolcj/order/wxTradeOrder/findOrderStatusByOrderId`,
      method: 'get' 
   },


  /***
   * 获取支付信息
   */
  wxPayTypeTradeOrderFindById: {
    url: `${prefix}/schoolcj/order/wxPayTypeTradeOrder/findById`,
    method: 'get'
  },

  /***
   * 获取订单中的用户详情
   */
   findOrderUserDetails: {
      url: `${prefix}/schoolcj/order/users/findOrderUserDetails`,
      method: 'get'
   },


  /***
   * 获取商品详情
   */
   findGoodsByOrderId: {
      url: `${prefix}/schoolcj/order/wxTradeOrder/findGoodsByOrderId`,
      method: 'get'
   },


  //获取订单列表
  // getOrderPagination: {
  //   url: `${prefix}/schoolcj/order/wxTradeOrder/findAll`, //"/wxTradeOrder/findAll?pageSize=" + this.pageInfo.pageSize + "&curPage=" + this.pageInfo.curPage,`,
  //   method: 'get',
  //   noEmptyString: true
  // },
  getOrderPagination: {
    url: `${newPath}/order/ty/order/orderListPage`, //"/wxTradeOrder/findAll?pageSize=" + this.pageInfo.pageSize + "&curPage=" + this.pageInfo.curPage,`,
    method: 'post',
    noEmptyString: true
  },

  //获取订单详情
  getOrderDetail: {
    url: `${prefix}/schoolcj/order/wxTradeOrder/findById`,
    method: 'get',
    noEmptyString: true
  },



  // 获取网校token
  getOnlineSchoolToken: {
    url: `${auth_prefix}/api/token/getToken`,
    method: "post",
    isQueryPost: true
  },

  //获取用户信息
  getCurrentUser: {
    url: `${prefix}/getCurrentUser`,
    method: 'get'
  },

  // 获取商品列表信息接口
  getGoodsList: {
    url: `${prefix}/schoolcj/goods/api/findAllGoodsForSellSystem`,
    method: "post",
  },
  // 获取商品详细信息接口
  getGoodsDetail: {
    url: `${prefix}/schoolcj/goods/front/findGoodsById`,
    method: "get"
  },
  // 获取推荐商品信息接口
  getRecommandGoodsList: {
    url: `${prefix}/schoolcj/goods/front/recommondGoods`,
    method: "get"
  },
  // 获取商品套餐分组信息
  getGoodsGruopInfo: {
    url: `${prefix}/schoolcj/goods/front/findComGoodsGroupInfo`,
    method: "get"
  },
  // 商品分类表格接口
  getGoodsCategories: {
    url: `${auth_prefix}/hqcenter/goods/api/fuzzySearchAllGoodsCategory`,
    method: "get"
  },
  // 产品线列表信息接口
  thrid_getAllProduct: {
    url: `${auth_prefix}/hqcenter/goods/api/findAllProduct`,
    method: "get"
  },
  // 商品规格接口
  getGoodsSpecs: {
    url: `${prefix}/schoolcj/goods/api/findSpByIdGoodsId`,
    method: "get"
  },
  // 商品类型下拉框
  getGoodsCategoriesSelect: {
    url: `${auth_prefix}/hqcenter/goods/api/findAllGoodsType`,
    method: "get"
  },
  // 商品等级下拉框
  getGoodsRankSelect: {
    url: `${auth_prefix}/hqcenter/goods/api/findAllGoodsRank`,
    method: "get"
  },
  // 检查商品是否下架
  thrid_checkGoodsStatus: {
    url: `${prefix}/schoolcj/goods/api/checkGoodsSchoolSellStatus`,
    method: "get"
  },
  // 获取套餐分组信息
  // http://10.0.98.78:8181/docs/goods//101
  thrid_getGoodsGroupInfo: {
    url: `${prefix}/schoolcj/goods/api/findComGoodsGroupBySpPriceId`,
    method: "get"
  },
  // 获取单品的信息
  findGoodsCourseBySpPriceId: {
    url: `${prefix}/schoolcj/goods/api/findGoodsCourseBySpPriceId`,
    method: "get"
  },
  
  // 生成订单接口
  thrid_createOrder: {
    url: `${prefix}/schoolcj/order/api/payModule/enrollPlatform/web/createOrder`,
    method: "post",
  },


  //免费商品支付接口
  thrid_free_createOrder: {
    url: `${prefix}/schoolcj/order/api/payModule/webApi/web/payZero`,
    method: "post",
  },

  // 获取支付链接
  thrid_getPayUrl: {
    url: `${prefix}/schoolcj/order/api/payModule/enrollPlatform/web/pay`,
    method: "post",
    isQueryPost: true
  },
  // 查询订单状态
  thrid_getOrderStatus: {
    url: `${prefix}/schoolcj/order/api/payModule/webApi/web/queryOrderStatus`,
    method: "post"
  },

  // 获取所有订单列表数据
  third_getAllOrderList: {
    url: `${prefix}/schoolcj/order/apiController/findAllOrder`,
    method: "post",
    // isQueryPost: true
  },

  // 获取订单变更记录
  findAllOrderExtraUpdate: {
    url: `${prefix}/schoolcj/order/apiController/findAllOrderExtraUpdate`,
    method: "post",
    // isQueryPost: true
  },


  //订单变更记录详情
  findOrderExtraUpdateById: {
    url: `${prefix}/schoolcj/order/apiController/findOrderExtraUpdateById`,
    method: "get",
    // isQueryPost: true
  },
  //订单变更记录撤销
  revokeOrderExtraUpdateById: {
    url: `${prefix}/schoolcj/order/apiController/revokeOrderExtraUpdateById`,
    method: "post",
  },
  //订单变更记录通过
  adoptOrderExtraUpdateById: {
    url: `${prefix}/schoolcj/order/apiController/adoptOrderExtraUpdateById`,
    method: "post",
  },
  //订单变更记录驳回
  rejectOrderExtraUpdateById: {
    url: `${prefix}/schoolcj/order/apiController/rejectOrderExtraUpdateById`,
    method: "post",
  },
  //订单变更记录删除
  deleteOrderExtraUpdateById: {
    url: `${prefix}/schoolcj/order/apiController/deleteOrderExtraUpdateById`,
    method: "post",
  },

  // 获取在线订单列表数据
  thrid_getOnlineOrderList: {
    url: `${prefix}/schoolcj/order/apiController/findAllOnlineOrder`,
    method: "post",
    // isQueryPost: true
  },
  // 根据订单id获取订单详情
  // http://wiki.hqbis.com/docs/ddglwd_tjr//202
  thrid_getOrderDetailInfo: {
    url: `${prefix}/schoolcj/order/apiController/findOrderDetailsByOrderId`,
    method: "get"
  },
  // 订单详情 支付明细按钮接口
  thrid_orderDetailPayinfo: {
    url: `${prefix}/schoolcj/order/apiController/findPaymentDetailsByOrderId`,
    method: "get"
  },
  // 订单详情 新的支付明细接口
  findPaySummaryAndInoutProjectByOrderId: {
    url: `${prefix}/schoolcj/finance/apiController/findInoutProjectByOrderId`,
    method: "get"
  },
  // 订单详情 协议明细按钮接口
  thrid_orderDetailSigninfo: {
    url: `${prefix}/schoolcj/order/apiController/findContractRecordByOrderId`,
    method: "get"
  },
  // 订单详情 商品详情按钮接口
  thrid_orderDetailGoodsinfo: {
    url: `${prefix}/schoolcj/order/apiController/findCourseAndContentByGoodsId`,
    method: "get"
  },
  // 订单详情 服务详情按钮接口
  thrid_orderDetailServiceinfo: {
    url: `${prefix}/schoolcj/order/apiController/findOrderGoodsServiceInfoByOrderId`,
    method: "get"
  },

  //服务详情接口
  findOrderGoodsServiceInfoByOrderId: {
    url: `${prefix}/schoolcj/order/wxTradeOrder/findOrderGoodsServiceInfoByOrderId`,
    method: 'get'
  },

  

  // 获取成交校区下拉框
  thrid_getDealSchoolList: {
    url: `${prefix}/schoolcj/goods/api/tradingOrderSchool`,
    method: "get"
  },
  // 变更销售老师
  thrid_updateOrderSaleTeacher: {
    url: `${prefix}/schoolcj/order/apiController/updateOrderTeacherSalesExtra`,
    method: "post"
  },
  // 变更招生老师
  thrid_updateOrderRecruitTeacher: {
    url: `${prefix}/schoolcj/order/apiController/updateOrderTeacherRecruitExtra`,
    method: "post"
  },
  // 变更成交校区接口
  thrid_updateOrderDealSchool: {
    url: `${prefix}/schoolcj/order/apiController/updateOrderSchoolExtra`,
    method: "post"
  },
  // 根据订单id获取变更招生老师数据
  thrid_getUpdateOrderRecruitTeacherById: {
    url: `${prefix}/schoolcj/order/apiController/findOrderTeacherExtraByOrderId`,
    method: "get"
  },
  // 根据订单id获取变更成交校区数据
  thrid_getUpdateOrderDealSchoolById: {
    url: `${prefix}/schoolcj/order/apiController/findOrderSchoolExtraByOrderId`,
    method: "get"
  },
  // 订单操作，取消订单接口
  thrid_cancelOrderByOrderId: {
    url: `${prefix}/schoolcj/order/api/payModule/web/order/cancelOrder`,
    // url: `${prefix}/schoolcj/order/apiController/cancelOrderByOrderId`,
    method: "get"
  },
  // 根据订单生成收款码
  thrid_getbillLinkInfo: {
    url: `${prefix}/schoolcj/order/api/payModule/webApi/web/billLinkInfo`,
    method: "post"
  },
  // 订单列表-订单详情
  thrid_queryOrderDetail: {
    url: `${prefix}/schoolcj/order/api/payModule/web/order/queryOrderDetail`,
    method: "get"
  },

  // 支付明细按钮接口
  getFindPaymentDetailsByOrderId: {
    url: `${prefix}/schoolcj/order/apiController/findPaymentDetailsByOrderId`,
    method: "get"
  },

  // 获取协议审核列表
  thrid_contractList: {
    url: `${prefix}/schoolcj/order/api/apiController/contract/list`,
    method: "post"
  },

  // 订单售后开始-------------------------
  // 获取售后类型
  thrid_getAfterSaleAllTypes: {
    url: `${prefix}/schoolcj/order/api/aftersaleType/findAllTypes`,
    method: "get"
  },
  
  // 获取订单售后列表
  third_getAfterSaleList: {
    url: `${prefix}/schoolcj/order/api/aftersale/findAll`,
    method: "post"
  },

  //售后订单下载
  third_downloadAfterSale: {
    url: `${prefix}/schoolcj/order/api/aftersale/export`,
    method: "get"
  },


  //获取组合套餐信息
  third_findComComGoodsInfo: {
    url: `${prefix}/schoolcj/goods/front/findComComGoodsInfo`,
    method:'get'
  },

  // 根据售后单id查看售后单详情
  third_getAfterSaleDetail: {
    url: `${prefix}/schoolcj/order/api/aftersale/findById`,
    method: "post"
  },
  // 创建售后单，退费
  third_createDroupOutOrder: {
    url: `${prefix}/schoolcj/order/api/aftersale/findAll`,
    method: "post"
  },
  // 创建售后单，升班
  third_createUpgradeOrder: {
    url: `${prefix}/schoolcj/order/api/aftersale/findAll`,
    method: "post"
  },
  // 创建售后单，转班
  third_createTransferOrder: {
    url: `${prefix}/schoolcj/order/api/aftersale/findAll`,
    method: "post"
  },
  // 审核通过接口
  thrid_approvalResolve: {
    url: `${prefix}/schoolcj/order/api/aftersale/checkAccess`,
    method: "get"
  },
  // 审核拒绝接口
  thrid_approvalReject: {
    url: `${prefix}/schoolcj/order/api/aftersale/checkReject`,
    method: "get"
  },
  // 升班，转班，退费选择订单列表
  thrid_choiceOrderList: {
    url: `${prefix}/schoolcj/order/api/aftersale/findOrderList`,
    method: "get"
  },

  // 升班，转班，退费选择订单列表
  thrid_choiceOrderListGZT: {
    url: `${prefix}/schoolcj/order/api/aftersale/findOrderListForGZT`,
    method: "post"
  },
  // 选择订单 根据ids获取订单详情
  third_choiceOrderDetailById: {
    url: `${prefix}/schoolcj/order/api/aftersale/getOrderDetail`,
    method: "get"
  },
  // 根据选择的订单ids进行退费计算
  thirid_calcDropoutMoneyById: {
    url: `${prefix}/schoolcj/order/api/aftersale/getPayInfo`,
    method: "get"
  },
  // 根据用户手机号查询余额信息
  thrid_getBalanceByMoblie: {
    url: `${prefix}/schoolcj/order/api/aftersale/userBalance/findByMobile`,
    method: "get"
  },
  // 提交售后单
  third_submitAfterSaleOrder: {
    url: `${prefix}/schoolcj/order/api/aftersale/submit`,
    method: "post"
  },
  // 保存售后单
  third_saveAfterSaleOrder: {
    url: `${prefix}//schoolcj/order/api/aftersale/save`,
    method: "post"
  },
  
  // 保存收款后的详情
  third_findOrderCollection: {
    url: `${prefix}/schoolcj/order/apiController/findOrderCollectionDetail`,
    method: "get"
  },

  // 商品规格点击第一行请求第二行
  third_GoodsSpBySpType: {
    url: `${prefix}/schoolcj/goods/front/findGoodsSpBySpType`,
    method: "get"
  },
  // 发送短信验证码
  sendAliSms: {
    url: `${prefix}/schoolcj/order/api/aliSms/sendAliSms`,
    method: 'get'
  },
  // 验证短信验证码
  verifyCode: {
    url: `${prefix}/schoolcj/order/api/aliSms/verifyCode`,
    method: 'get'
  },
  // 去蓝鲸内部服务后台查询用户信息
  getUserInfoByMobile: {
    url: `/mock/20/users/getUserInfoByMobile`,
    method: "get"
  },
  // 去蓝鲸内部服务  保存用户信息
  saveUserInfo: {
    url: `/qw_lj/users/save`,
    method: "post"
  },
  // 去蓝鲸内部服务  更新用户信息
  updateUserInfo: {
    url: `/qw_lj/users/update`,
    method: "post"
  },
  // 多升多转查询学员列表
  getUserListByGet: {
    url: `${prefix}/schoolcj/order/apiController/findAllOrderUser`,
    method: "post"
  },

  
  //获取组合套餐收支项信息
  findComComGoodsInoutProject: {
    url: `${prefix}/schoolcj/goods/front/findComComGoodsInoutProject`,
    method: 'get'
  },


  // 查询收费项目接口
  findInoutProjectForApi: {
    url: `${prefix}/schoolcj/goods/api/findInoutProjectForApi`,
    method: "get"
  },
  // 查询收支项目接口
  inoutProjectFindAll: {
    url: `${prefix}/hqcenter/goods/api/inoutProject/findAll`,
    method: "get"
  },
  // 查询收支项目模糊查询接口
  inoutProjectFindquery: {
    url: `${prefix}/hqcenter/goods/api/inoutProject/fuzzyQuery`,
    method: "get"
  },
  // 审批协议通过或驳回
  doAudit: {
    url: `${prefix}/schoolcj/order/api/apiController/contract/audit`,
    method: "post"
  },
  // 变更招生老师接口
  Getlearnphone: {
    url: `${prefix}/schoolcj/order/apiController/updateOrderLearnPhoneExtra`,
    method: "post"
  },
  // 根据用户id和商品id获取商品状态
  findOrderStatusByUserIdAndGoodsId: {
    url: `${prefix}/schoolcj/order/apiController/findOrderStatusByUserIdAndGoodsId`,
    method: "get"
  },
  // 根据规格id和用户id获取商品状态
  findOrderStatusByUserIdAndSpecificationId: {
    url: `${prefix}/schoolcj/order/apiController/findOrderStatusByUserIdAndSpecificationId`,
    method: "get"
  },
  // 检查升班转班是否复购
  doCheckRepeatBuy: {
    url: `${prefix}/schoolcj/order/api/aftersale/checkRepeatBuy`,
    method: "post"
  },
  // 售后单id查看售后单详情，保存后查看草稿
  aftersaleFindById: {
    url: `${prefix}/schoolcj/order/api/aftersale/findByIdForUpdate`,
    method: "get"
  },
  // 查看钉钉审核记录
  findProcessRecordById: {
    url: `${prefix}/schoolcj/order/api/dingTalk/findProcessRecordById`,
    method: "get"
  },
  // 删除接口
  aftersaleDelete: {
    url: `${prefix}/schoolcj/order/api/aftersale/deleteByIdStr`,
    method: "get"
  },
  // 售后单撤销接口
  aftersaleRepeal: {
    url: `${prefix}/schoolcj/order/api/aftersale/repeal`,
    method: "get"
  },
  // 获取商品预览地址接口
  previewGoods: {
    url: `${prefix}/schoolcj/goods/api/previewGoods`,
    method: "get"
  },
  // 获取商品规格价格信息
  findPriceInfoBySpPriceId: {
    url: `${prefix}/schoolcj/goods/api/findPriceInfoBySpPriceId`,
    method: "get"
  },
  // 付款明细接口
  findPaymentDetail: {
    url: `${prefix}/schoolcj/order/api/aftersale/findPaymentDetail`,
    method: "get"
  },
  // 获取学历商品的查询接口
  third_getfindAllgoodsSystem: {
    url: `${prefix}/schoolxl/goods/api/findAllGoodsForSellSystem`,
    method: "post"
  },
  // 获取自考，成考，网教班型和报考信息
  third_getfindSpByIdGoodsId: {
    url: `${prefix}/schoolxl/goods/api/findSpByIdGoodsId`,
    method: "get"
  },
  // 获取成考院校
  third_getfindEnrollingSchoolList: {
    url: `${prefix}/schoolxl/goods/api/findEnrollingSchoolList`,
    method: "get"
  },
  // 获取专业
  third_getfindEnrollingMajorList: {
    url: `${prefix}/schoolxl/goods/api/findEnrollingMajorList`,
    method: "get"
  },
  
  // 获取规格可使用贷款平台接口
  third_getfindPlatformBySpPriceId: {
    url: `${prefix}/schoolcj/goods/front/findPlatformBySpPriceId`,
    method: "get"
  },
  // 获取规格的贷款信息
  third_getfindLoanInfoBySpPriceId: {
    url: `${prefix}/schoolcj/goods/front/findLoanInfoBySpPriceId`,
    method: "get"
  },
  // 还款计划列表
  third_getTradeOrderfindAll: {
    url: `${prefix}/schoolcj/order/wxTradeOrderInstallment/findAll`,
    method: "post"
  },


  //商品课程信息
  //goodsIdStr 商品id字符串（用’,’隔开）
  third_findCourseByGoodsIdStr: {
    url:`${prefix}/schoolxl/goods/api/findCourseByGoodsIdStr`,
    method: 'get'
  },

  //商品班型收支项
  //spPriceIdStr  商品规格字符串（用”,”隔开）
  third_findInoutProjectForApi: {
    url: `${prefix}/schoolxl/goods/api/findInoutProjectForApi`,
    method: 'get'
    },
    // 获取规格的贷款信息(用应收金额对比)
  third_getcheckGoodsloan: {
    url: `${prefix}/schoolcj/goods/front/checkGoodsLoan`,
    method: "get"
  },
  // 售后退费计算
  third_getRefundtrial: {
    url: `${prefix}/schoolcj/order/api/aftersale/getRefundtrial`,
    method: "get"
  },
  // 还款计划详情接口
  third_getDetailsByOrderId: {
    url: `${prefix}/schoolcj/order/api/apiController/findRepaymentProjectDetailsByOrderId`,
    method: "get"
  },

  //自考获取套餐分组信息
  findGroupInfoByGoodsId: {
    url: `${prefix}/schoolxl/goods/api/findGroupInfoByGoodsId`,
    method: 'get'
  },

  //分组商品的报考信息
  findEnrollingInfo: {
    url: `${prefix}/schoolxl/goods/api/findEnrollingInfo`,
    method: 'get'
  },

  // 获取班型的信息
  findSpInfoByProvinceId: {
    url: `${prefix}/schoolxl/goods/api/findSpInfoByProvinceId`,
    method: 'post'
  },

  // 套餐获取课程信息
  findCourseBySpPriceIdStr: {
    url: `${prefix}/schoolxl/goods/api/findCourseBySpPriceIdStr`,
    method: 'post'
  },

  // 获取报考省份和院校
  getFindEnrollingInfoForApi: {
    url: `${prefix}/schoolxl/goods/api/findEnrollingInfoForApi`,
    method: 'get'
  },

  // 商品分类列表信息接口
  getFindAllGoodsCategory: {
    url: `${prefix}/hqcenter/goods/api/findAllGoodsCategory`,
    method: 'get'
  },

  //获取网校的用户信息
  getXlUserInfo: {
    url: `/_xl/getCurrentUser`,
    method: 'get'
  },
  
  // 判断选择订单时候的条件
  getOrderShowList: {
    url: `${prefix}/schoolcj/order/api/aftersale/checkOrder`,
    method: 'get'
  },
  // api/user/findAllNC //获取三种老师数据
  getTeacherFindAll: {
    url: `${prefix}/api/user/findAllNC`,
    method: 'get'
  },
  //获取天翼用户列表
  getTyUserList: {
    url: `${auth_prefix}/api/user/findAll`,
    method: 'get'
  },
  
  // 根据天翼userid来查询学员信息
  getUserInfoByTyUserId: {
    url: `${auth_prefix}/api/user/getUserInfoById`,
    method: "get",
  },
  // // 订单凭证列表(个人)
  // third_getMyInstanceList: {
  //   url: `${auth_prefix}/flow/api/custom/myInstanceList`,
  //   method: 'post',
  //   headers: {
  //     'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
  //   }
  // },
  // // 订单凭证列表(归属部门)
  // third_getBranchInstanceList: {
  //   url: `${auth_prefix}/flow/api/custom/branchInstanceList`,
  //   method: 'post',
  //   headers: {
  //     'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
  //   }
  // },
  // // 订单凭证列表(所有)
  // third_getAllInstanceList: {
  //   url: `${auth_prefix}/flow/api/custom/allInstanceList`,
  //   method: 'post',
  //   headers: {
  //     'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
  //   }
  // },
  // 订单凭证列表(个人)
  third_getMyInstanceList: {
    url: `${auth_prefix}/schoolcj/order/api/payVoucher/getMyInstanceList`,
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  },
  // 订单凭证列表(归属部门)
  third_getBranchInstanceList: {
    url: `${auth_prefix}/schoolcj/order/api/payVoucher/getBranchInstanceList`,
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  },
  // 订单凭证列表(所有)
  third_getAllInstanceList: {
    url: `${auth_prefix}/schoolcj/order/api/payVoucher/getAllInstanceList`,
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  },
  // 状态
  third_getCustomStatus: {
    url: `${auth_prefix}/flow/api/custom/status`,
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  },
  // 订单凭证列表(审核记录)
  third_getCustomHistory: {
    url: `${auth_prefix}/flow/api/custom/history`,
    method: 'post',
    headers: {
      'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
    }
  },
  // 凭证详情数据接口
  third_GetqueryPayVoucherDetailsByProcessInstanceId: {
    url: `${prefix}/schoolcj/order/api/payVoucher/queryPayVoucherDetailsByProcessInstanceId`,
    method: 'get',
  },
  // 当前订单的所有凭证
  third_GetqueryPayVoucherDetailsByOrderId: {
    url: `${auth_prefix}/schoolcj/order/api/payVoucher/queryPayVoucherDetailsByOrderId`,
    method: 'get',
  },
  // 编辑是查询凭证详情数据
  third_GetqueryPayVoucherEntityListByIdList: {
    url: `${prefix}/schoolcj/order/api/payVoucher/queryPayVoucherEntityListByIdList`,
    method: 'post',
  },
  // 通过凭证详情数据
  third_doPassApprove: {
    url: `${prefix}/schoolcj/order/api/payVoucher/approve`,
    method: 'post',
  },
  // 驳回凭证详情数据
  third_doRejectApprove: {
    url: `${prefix}/schoolcj/order/api/payVoucher/reject`,
    method: 'post',
  },
  // 删除凭证
  third_deleteVoucherByProcessInstanceId: {
    url: `${prefix}/schoolcj/order/api/payVoucher/deleteVoucherByProcessInstanceId`,
    method: 'post',
  },
  // 根据订单id获取收款项目数据接口
  third_getQueryOrderInOutProjectDto: {
    url: `${prefix}/schoolcj/finance/apiController/queryOrderInOutProjectDto`,
    method: 'get',
  },
  
  // 提交支付凭证接口
  third_doPayVoucher: {
    url: `${prefix}/schoolcj/order/api/payModule/webApi/web/payVoucher`,
    method: 'post',
  },

  // 上传图片
  third_doUpload: {
    url: `/qw_voucher/oss/oss-avatar/upload`,
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' }
  },
  // 获取收款方式
  third_findSettlementMethod: {
    url: `${auth_prefix}/hqcenter/goods/api/findSettlementMethod`,
    method: 'get',
  },
  // 获取银行账户
  third_findFundMethod: {
    url: `${auth_prefix}/hqcenter/goods/api/findFundMethod`,
    method: 'get',
  },
  // 获取收款方式
  third_innerfindSettlementMethod: {
    url: `${auth_prefix}/hqcenter/goods/inner/findSettlementMethod`,
    method: 'get',
  },
  // 支付方式下拉接口
  queryByConfigureSettleMethodData: {
    url: `${auth_prefix}/hqcenter/goods/inner/queryByConfigureSettleMethodData`,
    method: 'get',
  },
  // 查询返回升班转班收支项目表数据
  queryBySpStrAndGoodsTemp: {
    url: `${prefix}/schoolcj/finance/orderCollectionItemsController/queryBySpStrAndGoodsTemp`,
    method: 'post',
  },
  // 订单取消和关闭权限
  checkOutOrderCancelAndClose: {
    url: `${prefix}/schoolcj/order/apiController/checkOutOrderCancelAndClose`,
    method: 'get',
  },
  // 关闭订单
  doCloseAuthorityByAuthority: {
    url: `${prefix}/schoolcj/order/api/apiController/closeAuthorityByAuthority`,
    method: 'post',
  },
  // 查询订单在售后的原订单
  aftersaleGetOriginOrder: {
    url: `${prefix}/schoolcj/order/api/aftersale/getOriginOrder`,
    method: 'get',
  },
  // 身份证号码检验
  doIdCardValidate: {
    url: `${prefix}/schoolcj/order/api/util/idCardValidate`,
    method: 'post',
    isUrlParams: true
  },
  // 协议查看
  getPreviewUrlForBestSign: {
    url: `${prefix}/schoolcj/order/api/contract/web/getPreviewUrlForBestSign`,
    method: 'post',
  },
  // 查询订单在售后的原订单
  aftersaleGetOriginOrder: {
    url: `${prefix}/schoolcj/order/api/aftersale/getOriginOrder`,
    method: 'get',
  },
  // 根据订单id修改订单的phone、name、idcard
  doUpdateOrderPhone: {
    url: `${prefix}/schoolcj/order/adminController/updateOrderPhone`,
    method: 'post',
  },
  // 根据订单id，和 aftersaleInoutProjectList收支表格 获取总部退回校区余额
  getBalanceReturnCampus: {
    url: `${prefix}/schoolcj/order/api/aftersale/proxy/info`,
    method: 'post',
  },
  // 根据订单id修改订单的phone、name、idcard
  doSaveUser: {
    url: `${prefix}/schoolcj/order/adminController/saveUser`,
    method: 'post',
  },
  // 判断商品是否配置就业协议模板
  employmentContract: {
    url: `${prefix}/schoolcj/order/api/contract/web/employmentContract`,
    method: 'get',
  },
  // 批量刷新异常协议数据
  doBatchRefresh: {
    url: `${prefix}/schoolcj/order/api/contract/web/batchRefresh`,
    method: 'post',
    isUrlParams: true
  },
  // 订单详情保存学历字段
  updateOrderEducation: {
    url: `${prefix}/schoolcj/order/api/apiController/updateOrderEducation`,
    method: 'post',
  },
  // 流水列表
  getStatementFindAll: {
    url: `${prefix}/schoolcj/order/api/statement/findAll`,
    method: 'get',
  },

  // 流水订单使用详情
  getFindStatementOrder: {
    url: `${prefix}/schoolcj/order/api/statement/findStatementOrder`,
    method: 'get',
  },

  // 报名选择班型商品时候触发
  thrid_checkCost: {
    url: `${prefix}/schoolcj/order/api/payModule/webApi/web/checkCost`,
    method: "post",
  },

  // 查询订单成本变更
  third_orderCostUpdate: {
    url: `${prefix}/schoolxl/goods/orderCostUpdate/findAll`,
    method: "get"
  },

  // 变更成本上传
  third_uploadPic: {
    url: `${prefix}/schoolxl/goods/file/uploadPic`,
    method: "post"
  },

  // 变更成本提交 
  third_orderCostUpdatesave: {
    url: `${prefix}/schoolxl/goods/orderCostUpdate/save`,
    method: "post"
  },
  // 更新学员信息时候保存
  thrid_updateOrdert: {
    url: `${newPath}/order/api/open/v1/updateOrder`,
    method: "post",
  },
  // 判断价格是否可以通过
  thrid_checkZeroOrder: {
    url: `${prefix}/schoolcj/order/api/payModule/enrollPlatform/web/checkZeroOrder`,
    method: "post",
  },
  // 更新报考院校信息保存
  thrid_updateEnrollInfo: {
    url: `${prefix}/schoolcj/order/api/apiController/updateEnrollInfo`,
    method: "post",
  },

  // 查询零元审批列表
  third_applyList: {
    url: `${prefix}/schoolcj/order/api/order/apply/list`,
    method: "post"
  },
  // 新增零元审批列表
  third_processAdd: {
    url: `${prefix}/schoolcj/order/api/order/apply/process`,
    method: "post"
  },
  // 退费方式 获取接口
  findRefundMethod: {
    url: `${prefix}/hqcenter/goods/api/findRefundMethod`,
    method: "get"
  },
}