/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-10-09 14:19:45
 * @LastEditors: crm
 * @LastEditTime: 2025-03-20 10:07:40
 */
import axios from "axios";

const baseUrl = "/qw_report_api";
const base = "/qw_api";

// 查询校区客户跟进仪表盘
export const getQuerySchoolFollowReport = (data) => {
  return axios.post(`${baseUrl}/report/querySchoolFollowReport`, data).then(res => res.data) 
}
// 查询仪表盘客户详情
export const getQueryCustomerDetail = (data) => {
  return axios.post(`${baseUrl}/report/customer/queryCustomerDetail`, data).then(res => res.data) 
}
// 根据用户数据权限获取校区列表 
export const getQuerySchoolListdel = () => {
  return axios.post(`${base}/user/dept/scope/deal/list`).then(res => res.data) 
}
export const getQuerySchoolList = () => {
  return axios.post(`${base}/workbench/dept/querySchoolList`).then(res => res.data) 
}
// 查询招生业绩报表
export const getQueryTeacherReport = (data) => {
  return axios.post(`${baseUrl}/report/recruit/queryRecruitTeacherReport`, data).then(res => res.data) 
}


/**
 * 漏斗模型数据
 * @param {*} param0 
 * @returns 
 */
 export function queryFunnelModel(data){
  return axios.post(`${baseUrl}/report/funnelModel/queryFunnelModelOne`,data).then(res=>res.data)
}

// 职位档案信息 - 获取列表
export const getEmploymentPositionList = (data) => {
  return axios.post(`${baseUrl}/report/employment/employmentPositionList`,data).then(res => res.data) 
}
// 职位档案信息 - 新增
export const employmentPositionAdd = (data) => {
  return axios.post(`${baseUrl}/report/employment/employmentPositionAdd`,data).then(res => res.data) 
}
// 职位档案信息 - 编辑/删除
export const employmentPositionUpdate = (data) => {
  return axios.post(`${baseUrl}/report/employment/employmentPositionUpdate`,data).then(res => res.data) 
}

// ===
// 就业统计 - 获取列表
export const getEmploymentInformationList = (data) => {
  return axios.post(`${baseUrl}/report/employment/employmentInformationList`,data).then(res => res.data) 
}
// 就业统计 - 新增
export const employmentInformationAdd = (data) => {
  return axios.post(`${baseUrl}/report/employment/employmentInformationAdd`,data).then(res => res.data) 
}
// 就业统计 - 编辑/删除
export const employmentInformationUpdate = (data) => {
  return axios.post(`${baseUrl}/report/employment/employmentInformationUpdate`,data).then(res => res.data) 
}
// 就业统计 - 导出
export const exportEmploymentInformation = (data) => {
  return axios.post(`${baseUrl}/report/employment/export`,data).then(res => res.data) 
}

// 获取城市列表
export const getCityList = (data) => {
  return axios.post(`${baseUrl}/report/employment/city`,data).then(res => res.data) 
}