/*
 * @Description:
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-28 14:22:07
 * @LastEditors: Seven
 * @LastEditTime: 2022-02-28 15:28:30
 */
import axios from "axios";
import { MessageBox, Message } from 'element-ui';
// import vue from "vue";
import $HqNotification from "components/notification";
// import router from "../router";
// import $HqNotification from "components/notification";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import Cookies from "js-cookie";

axios.defaults.headers["token"] = localStorage.getItem("userToken") || "";
axios.defaults.headers.post["Content-Type"] = "application/json";

NProgress.inc(0.2);
NProgress.configure({ easing: "ease", speed: 600, showSpinner: false });

axios.interceptors.request.use((config) => {
  // 启动进度条
  NProgress.start();
  const userToken = localStorage.getItem('userToken')
  if (userToken !== null) {
    config.headers['token'] = userToken
  }
  if(config.headers['onlineSchoolToken']){
    config.headers['token'] = config.headers['onlineSchoolToken']
  }

  config.params = {
    _t: Date.parse(new Date()) / 1000,
    ...config.params
  }
  return config;
}, error => {
  return Promise.reject(error)
});

axios.interceptors.response.use((res) => {
  const code = res.data.code
  if (code === 1130 || code === 1131) {
    // Notification.destroy('authc_error')
    $HqNotification({
      type: "info",
      title: '温馨提示',
      message: res.data.msg,
      duration: 0,
      name: 'authc_error'
    })
    setTimeout(() => {
      if (process.env.VUE_APP_TITLE === "development") {
        // location.href = `http://crm.dev.hqjy.com/#/login`
      } else if (process.env.NODE_ENV === "testing") {
        // Cookies.remove('testUserToken', { domain: ".hqjy.com" })
        location.href = `http://crm.hqbis.com:18080/#/login`
      } else if(process.env.NODE_ENV === "beta") {
        location.href = `http://crm.beta.hqjy.com/#/login`
      } else if(process.env.NODE_ENV === "production") {
        Cookies.remove('proUserToken', { domain: ".hqjy.com" })
        location.href = `http://crm.hqjy.com/#/login`
      }
    }, 1000)
    // router.replace({
    //   path: 'http://crm.dev.hqjy.com/#/login?redirect=%2F',
    //   query: {redirect: router.currentRoute.fullPath}
    // }, () => {
    //   store.commit("logout", window.Vue);
    // })
    // 关闭进度条
    NProgress.done();
    return Promise.reject(res.data.msg)
  } else {
    // 关闭进度条
    NProgress.done();
    return res
  }
}, error => {
  if (error.response) {
    let message = error.response.data.msg
    switch (error.response.status) {
      case 401:
        Message({
          type: "warning",
          message: "用户无权限进行此操作",
          offset: 140,
          duration: 2000,
          showClose: true
        })
        break;
      case 403:
        $HqNotification({
          type: "info",
          title: '温馨提示',
          message: message,
          duration: 0,
          name: 'authc_error'
        })
        setTimeout(() => {
          if(process.env.VUE_APP_TITLE === "development") {
            // location.href = `http://crm.dev.hqjy.com/#/login`
          } else if(process.env.NODE_ENV === "testing") {
            // Cookies.remove('testUserToken', { domain: ".hqjy.com" })
            location.href = `http://crm.hqbis.com:18080/#/login`
          } else if(process.env.NODE_ENV === "beta") {
            location.href = `http://crm.beta.hqjy.com/#/login`
          } else if(process.env.NODE_ENV === "production") {
            Cookies.remove('proUserToken', { domain: ".hqjy.com" })
            location.href = `http://crm.hqjy.com/#/login`
          }
        }, 1000)  
        break;
      case 500:
        MessageBox({
          type: "error",
          message: message,
          showInput: true,
          inputValue: error.response.data.result['com.hqjy.mustang.exception'],
          inputType: "textarea",
          center: "true"
        })
        break;
      case 504:
        Message({
          type: "warning",
          message: "网络连接超时",
          offset: 140,
          duration: 2000,
          showClose: true
        })
        break;  
      default:
        Message({
          type: "warning",
          message: "服务器繁忙，请稍后再试",
          offset: 140,
          duration: 2000,
          showClose: true
        })
        break;
    }
  }
  return Promise.reject(error)
});

export default axios;



