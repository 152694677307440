/*
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-06-18 15:28:38
 * @LastEditors: crm
 * @LastEditTime: 2025-03-21 15:54:29
 */
import { Message } from 'element-ui';
import { reqDictByCode } from "api/dict";
import { reqTrackInfoByContact, reqCheckCall, checkHandle, getResTeachList } from "api/public";
import { reqLogincall } from "api/call/tinet-api";
import Socket from '@/utils/webSocket'


const data = {
  state: {
    // 角色列表
    roleList: [],
    allRoleList: [],
    // 部门数据
    deptData: {
      tree: [], // 部门树
      list: [] // 部门列表
    },
    // 角色数据
    roleData: {
      tree: [], // 角色树
      list: [] // 角色列表
    },
    dictData: {},
    teacherData: [],
    // 客户轨迹查询ID
    tarckCustomerId: null,
    // 业务跟进查询ID
    followCustomerId: null,
    // 拨打频次标识 1正常，2限制冻结
    quantityCode: 1,
    // 客户详情模态框展示隐藏
    drawer: false,
    // 用户id
    detailcustomerId: null
  },
  mutations: {
    setRoleList(state, result) {
      state.roleList = result
    },
    setAllRoleList(state, result) {
      state.allRoleList = result
    },
    setDeptData(state, result) {
      state.deptData = result
    },
    setRoleData(state, result) {
      state.roleData = result
    },
    setDictData(state, {
      dictCode,
      result
    }) {
      state.dictData[dictCode] = result
    },
    setTeacherData(state, result) {
      state.teacherData = result
    },
    setTarckCustomerId(state, result) {
      state.tarckCustomerId = result
    },
    setFollowCustomerId(state, result) {
      state.followCustomerId = result
    },
    setQuantityCode(state, result) {
      state.quantityCode = result
    },
    setDrawer(state, result) {
      state.drawer = result
    },
    setdetailcustomerId(state, result) {
      state.detailcustomerId = result
    }
  },
  actions: {
    // 获取老师列表
    getTeacherData: async ({ state, commit },refresh) => {
      const res = await getResTeachList()
      if (res.code === 0) {
        commit("setTeacherData", res.result)
      } 
      return res.result;
    },
    // 获取数据字典项
    getDictData: async ({ state, commit }, dictCode, refresh) => {
      const setDictData = await new Promise(function (resolve) {
        if (state.dictData[dictCode] === undefined || refresh) {
          reqDictByCode(dictCode).then((data) => {
            let { code, result } = data;
            if (code === 0) {
              commit("setDictData", {
                dictCode: dictCode,
                result: result,
              })
              resolve(result)
            } else {
              resolve([])
            }
          })
        } else {
          resolve(state.dictData[dictCode])
        }
      })
      return setDictData;
    },
    // 获取数据字典项修正--action只能带一个payload
    getDictDataCorrect: async ({ state, commit }, { dictCode, refresh }) => {
      const setDictData = await new Promise(function (resolve) {
        if (state.dictData[dictCode] === undefined || refresh) {
          reqDictByCode(dictCode).then((data) => {
            let { code, result } = data;
            if (code === 0) {
              commit("setDictData", {
                dictCode: dictCode,
                result: result,
              })
              resolve(result)
            } else {
              resolve([])
            }
          })
        } else {
          resolve(state.dictData[dictCode])
        }
      })
      return setDictData;
    },
    // 根据客户编号，跳转道不同的商机详情页面，用于弹屏和消息中心的跳转
    gotoCustomerInfo({ state, commit }, customerId) {
      console.log(state)
      console.log(commit)
      // 请求检测商机是否是当前用户
      checkHandle(customerId).then(res => {
        console.log(res, '呼叫限制')
        let { code } = res
        if (code === 0) {
        } else {
          Message({
            type: "warning",
            message: '注意：您不是此号码当前归属人',
            duration: 10000,
            showClose: true
          })
        }
      })
    },
    // 众投外呼
    getOrangePhone({ commit, dispatch }, params) {
      console.log('params众投外呼', params);
      console.log(commit)
      console.log('dispatch', dispatch);
      // try {
      //   reqLogincall(params).then(res => {
      //     console.log('进入了众投外呼判断了', res);
      //     if (res.code != 0) {
      //       Message.error(res.msg)
      //     } else {
      //       let _this = this
      //       new Socket({
      //         // soket服务地址
      //         socketUrl: 'ws://crm1.zthltx.com:345/websocket/',
      //         extnumber: params.toPhone,
      //         // 回调data
      //         soketCallback: (data) => {
      //           console.log('data00000000', _this, this, data);
      //           dispatch('soketCallback', data)
      //           // this.soketCallback(data)
      //           // _this.$store.commit('setTinetCallStatuszthl')
      //         }
      //       })
      //     }
      //   })
      // } catch (erroe) {

      // }
    },
    soketCallback(data) {
      switch (data.data.status) {
        case "idle": // 空闲
          window.parent.vue.$store.commit('setTinetCallStatuszthl', 'idle')
          window.parent.vue.$store.commit('setTinetLoginStatus', {
            status: 'idle',
            type: 'success',
            show: '空闲'
          })

          // that.lockReconnect = true;
          break;
        case "offline": // 离线
          window.parent.vue.$store.commit('setTinetCallStatuszthl', 'offline')
          window.parent.vue.$store.commit('setTinetLoginStatus', {
            status: 'offline',
            type: 'primary',
            show: '离线'
          })

          // that.lockReconnect = true;
          break;
        case "hangup": // 挂断
          console.log('this', window.parent.vue);
          window.parent.vue.$store.commit('setTinetCallStatuszthl', 'hangup')
          window.parent.vue.$store.commit('setTinetLoginStatus', {
            status: 'hangup',
            type: 'success',
            show: '空闲'
          })

          // that.lockReconnect = true;
          break;
        case "answer": // 通话中
          that.callback(data);
          window.parent.vue.$store.commit('setTinetCallStatuszthl', 'answer')
          window.parent.vue.$store.commit('setTinetLoginStatus', {
            status: 'answer',
            type: 'primary',
            show: '通话中'
          })
          // that.lockReconnect = true;
          break;
        case "ring": // 响铃
          that.callback(data);
          window.parent.vue.$store.commit('setTinetCallStatuszthl', 'ring')
          window.parent.vue.$store.commit('setTinetLoginStatus', {
            status: 'ring',
            type: 'primary',
            show: '响铃中'
          })
          // that.lockReconnect = true;
          break;
        default:
          console.log('进入到回调', that.callback == 'function');

      }
    },
    // 根据号码进行弹屏
    /**
     * @param {*} customerId 不传表示callCenter组件里面的拨号 拨打，传表示contact组件拨打
     */
    async gotoCustomerInfoByPhone(context, {
      tel,
      customerId = 0
    }) {
      // 客户列表里面contack组件里面的图标拨打直接传customerId过来，不用走下面这一步
      console.log('tel', tel);
      // if (customerId === 0) {
      //   // 获取customer信息
      //   const res = await reqTrackInfoByContact(0, {
      //     detail: tel.tel,
      //     trackType: tel.trackType
      //   })
      //   if (res.code === 0) {
      //     customerId = res.result.customerId
      //     // 如果当前号码存在归属人
      //     context.dispatch('gotoCustomerInfo', customerId)
      //   } else if (res.code === 5004) {
      //     context.commit('setIfMultiTrack', true)
      //     return false
      //   } else {
      //     Message.info('无此号码对应客户')
      //   }
      // }
      // // 拨打频次校验
      // const res1 = await reqCheckCall({
      //   customerId: customerId,
      //   phone: tel.tel
      // })
      // if (res1.code === -1) {
      //   Message.info(res1.msg)
      //   context.commit('setQuantityCode', 2)
      //   return false
      // }
      // // 获取userFieid
      await context.dispatch('getUserFieIdGetByCustomerId', {
        customerId,
        tel
      })
      // let paramTel = {
      //   toPhone: tel,
      //   customerId: customerId ? customerId : 0
      // }
      // await context.dispatch('getOrangePhone', paramTel)
    }
  },
  // 相当于计算属性，可以自定义state所相关的属性，比如取反
  getters: {
    tarckCustomerId(state) {
      return state.tarckCustomerId
    },
    followCustomerId(state) {
      return state.followCustomerId
    },
    getterDictData(state) {
      return state.dictData
    },
    getterTeacherData(state) {
      return state.teacherData
    },
    getQuantityCode(state) {
      return state.quantityCode
    },
    getdetailcustomerId(state) {
      return state.detailcustomerId
    }
  }
}

export default data